import React, { useState } from 'react';
import './Feed.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom';

const FeedBack = () => {
  const [rating1, setRating1] = useState(0); // State to track the selected rating for the first service
  const [rating2, setRating2] = useState(0); // State to track the selected rating for the second service

  // Function to handle click on a star for the first service
  const handleClick1 = (selectedRating) => {
    setRating1(selectedRating);
  };

  // Function to handle click on a star for the second service
  const handleClick2 = (selectedRating) => {
    setRating2(selectedRating);
  };

  // Function to render individual star icons
  const renderStars = (rating, handleClick) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <img
          key={i}
          src={i <= rating ? "/star2.svg" : "/Star1.svg"}
          alt={`Star ${i}`}
          className="star"
          onClick={() => handleClick(i)}
        />
      );
    }
    return stars;
  };

  return (
    <div className='container'>
      <center><h1 className='he1'>How was your experience with this service?</h1></center>
      <h1 className='he2'>Were our staff well-informed and friendly?</h1>
      <div className="star-rating">
        {renderStars(rating1, handleClick1)}
      </div>
      <h1 className='he3'>Rate the call quality</h1>
      <div className="star-rating2">
        {renderStars(rating2, handleClick2)}
      </div>
     
      
      <textarea id="message" className='t1' name="message" placeholder='Comment'></textarea>
      <Link to="/WebPage/WebPage">
      <img src="/Rectangle12.svg" alt="Logo" className="imgicon15" />
      <h1 className='he4'>Submit</h1>
      </Link>
      <Link to="/WebPage/WebPage">
      <img src="/Rectangle13.svg" alt="Logo" className="imgicon16" />
      <h1 className='he5'>Skip</h1>
      </Link>
      <img src="/Group50.svg" alt="Logo" className="logolast1" />
      
    </div>
  );
};

export default FeedBack;
