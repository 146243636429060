import React from "react";
import './First.css'; // Import your CSS file for styling

const FirstPage = () => {
  
   return (
        
      <div className="container">
      <h1 className="fh1">We can’t talk to you right now or we are <br></br> busy with another call</h1>
      <img src="/Group 2.svg" alt="Image" className="fimg" />
      <img src="/Group112.svg" alt="Image" className="fimg1" />
      <h2 className="fh2">Come back when the dot is not plain<br />9am EST - 10pm EST</h2>
      <img src="/Group50.svg" alt="Logo" className="fimg2" />
      </div>
      
    );
  };

  export default FirstPage;