import React, { useState, useEffect } from 'react';
import PermissionPopup from './PermissionPopup'; // Import the new popup component
import './Permission.css'; // Ensure this includes your styles for Permission
import { useNavigate } from 'react-router-dom';
import { useData } from '../Context';
import { useModalContext } from '../hooks/useModalState';
import { saroAPI, retailerNAME, imageUrl } from './constant';
import { FeatureFlags } from './featureFlags';
import footerlogo1 from '../images/SHOPANDRETAILlogo.png';
import './customStyle.css';


import boy from '../images/boy.png';

const Permission = ({ onPageChange, setShowModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false); // State for showing the permission popup
  const [enabledFeatures, setEnabledFeatures] = useState([FeatureFlags.VOICE, FeatureFlags.VIDEO]); // State for enabled features

  const navigate = useNavigate();
  const { setData } = useData();
  const { setModalState } = useModalContext();

  // Determine browser type
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const browserType = isSafari ? 'safari' : isChrome ? 'chrome' : 'other';

  const handleVoiceCallClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      if (stream) {
        startCallWithPermission({ audio: true, video: false }, FeatureFlags.VOICE);
      }
    } catch (error) {
      console.error('Permission denied or error:', error);
      setShowPermissionPopup(true); // Show the popup if permission is denied
    }
  };

  const handleVideoCallClick = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      if (stream) {
        startCallWithPermission({ audio: true, video: true }, FeatureFlags.VIDEO);
      }
    } catch (error) {
      console.error('Permission denied or error:', error);
      setShowPermissionPopup(true); // Show the popup on any view if permission is denied
    }
  };

  const startCallWithPermission = async (mediaConstraints, callType) => {
    try {
      const customerName = 'John';
      const retailerName = retailerNAME;

      if (mediaConstraints.audio || mediaConstraints.video) {
        fetchCallDetails(customerName, retailerName, callType);
      } else {
        console.error('No media permissions granted. Cannot start the call.');
        alert('You need to grant the required permissions to connect.');
      }
    } catch (error) {
      console.error('Error starting the call:', error);
      alert('There was a problem starting the call.');
    }
  };

  const fetchCallDetails = async (customerName, retailerName, callType) => {
    const retailerNameNew = retailerName;
    try {
      setIsLoading(true);
      const response = await fetch(`${saroAPI}/startCall`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          retailerName: retailerNameNew
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { sessionId, token, callUUID, userName: clerkName } = responseData.data;
        setData({
          'sessionID': sessionId,
          'token': token,
          'callUUID': callUUID,
          'userName': clerkName,
          'retailerName': retailerNameNew,
          'callType': callType
        });
        onPageChange("Main");
      } else {
        alert('Oh!!! All clerks are busy now.');
      }
    } catch (error) {
      alert('Oh!!! All clerks are busy now.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem('isVideoOn', isVideoOn);
  }, [isVideoOn]);

  useEffect(() => {
    sessionStorage.setItem('isMicrophoneOn', isMicrophoneOn);
  }, [isMicrophoneOn]);

  useEffect(() => {
    setModalState('dynamic');
  }, []);

  return (
    <div>
      {showPermissionPopup && (
        <PermissionPopup onClose={() => setShowPermissionPopup(false)} browserType={browserType} />
      )}
      
  <div className={window.innerWidth > 760 ? 'centered-container ' : 'container-fluid fullheight d-flex flex-column justify-content-around bodyContainer'}>
        <div className="row bodyContainer2ndDiv marginRL">
          <div className="col-12">
            <h3
              className="header4"
              style={{
                marginTop: '20px',
                fontSize: '20px',
                lineHeight: 'inherit',
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              Do you want people to hear you in the call?
            </h3>
            <h2
              style={{
                textAlign: 'center',
                marginTop: '10px',
                marginBottom:'40px',
                fontSize: '14px',
                color: '#4F4F4F',
                padding: window.innerWidth > 760 ? '0 10px' : '0 20px',
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              You can still turn off your microphone at any time during the call.
            </h2>
          </div>
        </div>
        <div className="row d-flex w-full marginRL">
        <div className="col-6 d-flex align-items-center justify-content-center imageDiv w-full">
  <img
    src={`${imageUrl}${boy}`}
    alt="Image"
    className="img-fluid ms-9"
    id="cimage1"
    style={{
      width: window.innerWidth <= 768 ? '130%' : 'auto',
      marginTop: window.innerWidth <= 768 ? '-20px' : '0px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    }}
  />
</div>

          {isLoading && (
            <div className="overlay">
              <div className="loader"></div>
            </div>
          )}
          <div className="col-6 d-flex flex-column justify-content-center contentDiv w-full">
            <div className="button-group d-flex flex-column align-items-center">
              {enabledFeatures.includes(FeatureFlags.VOICE) && (
                <>
                  <p style={{ textAlign: 'center', marginTop: window.innerWidth > 768 ? '0px' : '-50px', marginBottom: '10px', fontSize: "13px", fontFamily: "'Roboto', sans-serif", display: window.innerWidth <= 768 ? 'none' : 'block', }}>
                  Your voice is all that’s needed — microphone access will be enough for us to connect!
                  </p>
                  <button className="bb1 voice-button"
                    style={{ marginBottom: window.innerWidth > 768 ? '20px' : '4px',
                      marginTop: window.innerWidth > 768 ? '0px' : '-35px',
                      fontFamily:"'Roboto', sans-serif",
                      fontSize:"12px",
                      
                     }}


                    onClick={handleVoiceCallClick}>
                    Join with microphone
                  </button>
                </>
              )}
              {enabledFeatures.includes(FeatureFlags.VIDEO) && (
                <>
                  <p style={{ textAlign: 'center', marginTop: window.innerWidth > 768 ? '0px' : '10px', marginBottom: '0px', fontSize: "13px", fontFamily: "'Roboto', sans-serif", display: window.innerWidth <= 760 ? 'none' : 'block', }}>
                  We'll need your microphone and camera — let's make it engaging!
                  </p>
                  <button className="bb1Outline video-button"
                    style={{
                      marginTop: window.innerWidth > 768 ? '10px' : '7px',
                      width: '220px',
                      height: '24px',
                      fontSize: '12px',
                      padding: '0',
                      fontFamily:"sans-serif"


                    }}
                    onClick={handleVideoCallClick}>
                    Join with microphone and camera
                  </button>

               
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row marginRL">
  <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100px', marginBottom: window.innerWidth > 768 ? '-30px' : '-40px' }}>
    <a 
      href="https://shoparonline.com/" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ 
        textDecoration: 'none', 
        color: 'black', 
        display: 'flex', 
        alignItems: 'center',  // Ensures vertical alignment
        justifyContent: 'center'
      }}
    >
      <span 
        style={{ 
          marginRight: '5px', 
          fontSize: '10px', 
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          lineHeight: '11px' // Matches the line height with the logo height
        }}
      >
        Powered by
      </span>
      <img 
        src={footerlogo1} 
          className="footer" 
          id="footer" 
          alt="Saro Logo" 
          style={{
           maxWidth:"100%", // Matches the logo height with the text line height
            height: "auto",
            display: 'block',
            verticalAlign: 'middle' // Aligns the logo vertically in the middle
          }} 
        />
    </a>
  </div>
</div>
      </div>
    </div>
  );
};

export default Permission;
