import { createContext, useContext } from 'react'

export const ModalContext = createContext(null)


export const useModalContext = () => {
    return useContext(ModalContext)
}




// context/MediaContext.js
// import { createContext, useContext, useState } from 'react';

// export const ModalContext = createContext(null);

// export const MediaProvider = ({ children }) => {
//   const [isMicrophoneOn, setIsMicrophoneOn] = useState(true);
//   const [isVideoOn, setIsVideoOn] = useState(true);
//   const [modalState, setModalState] = useState(null); // Assuming you have this in your context

//   return (
//     <ModalContext.Provider value={{ isMicrophoneOn, setIsMicrophoneOn, isVideoOn, setIsVideoOn, modalState, setModalState }}>
//       {children}
//     </ModalContext.Provider>
//   );
// };

// export const useModalContext = () => {
//   return useContext(ModalContext);
// };



