import React, { useState, useEffect } from 'react';
import './WebPage.css'; // Import your CSS file for styling
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Body from '../Body';
import Headers from '../SpeakPage/Headers';
import Main from '../CallPage/Main';
import FeedPage from "../FeedBack/FeedPage";
import FirstPage from "../FirstPage/FirstPage";
import Permission from "../Permission";
import { ModalContext } from '../../hooks/useModalState';
import myImage from '../../images/NewGIF.gif';
import videoIcon from '../../images/video-solid.svg';
import welcomeLady from '../../images/saro-welcome-lady.mp4';
import welcomeLadyPoster from '../../images/saro-welcome-lady-poster.jpg';
import { hostingURL, saroAPI, retailerNAME } from '../constant'; // Assuming you have saroAPI and retailerNAME here
import '@fortawesome/fontawesome-free/css/all.min.css';
import axios from 'axios'; // Import Axios for API calls

const WebPage = () => {
  const [showModal, setShowModal] = useState(false); // Set initial state to false to hide the modal
  const [currentPage, setCurrentPage] = useState("Body"); // State to track the current page in the modal
  const [callDuration, setCallDuration] = useState(0); // State to track call duration
  const [modalState, setModalState] = useState('dynamic');
  const [showPopup, setShowPopup] = useState(false); // State to control if the popup should be shown

  // Function to toggle the visibility of the modal
  const toggleModal = () => {
    setShowModal(!showModal);
    if (!showModal) {
      setCurrentPage("Body"); // Reset to Body when opening the modal
    }
  };

  // Function to handle navigation to different pages within the modal
  const handleNavigation = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (showModal) {
      // Start updating the call duration every second when the modal is shown
      const interval = setInterval(() => {
        setCallDuration(prevDuration => prevDuration + 1);
      }, 1000);

      // Clear the interval when the modal is closed
      return () => clearInterval(interval);
    }
  }, [showModal]);

  // API call to authenticateToken on component load
  useEffect(() => {
    const authenticateToken = async () => {
      try {
        const response = await axios.post(`${saroAPI}/authenticateToken`, {
          retailerName: retailerNAME,
        });

        if (response.data.data) {
          setShowPopup(true); // Show popup if API returns success
        } else {
          setShowPopup(false); // Hide popup if API returns failure
        }
      } catch (error) {
        console.error("Error authenticating token:", error);
        setShowPopup(false); // Hide popup in case of error
      }
    };

    authenticateToken(); // Call the function when the page loads
  }, []);

  return (
    <div className="container">
      {showPopup && (
        <div
          className="popup-trigger"
          onClick={toggleModal}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
          }}
        >
          {/* Image container */}
          <div
            style={{
              position: 'relative',
              width: '120px', // Adjust size
              aspectRatio: '2000 / 1727', // To keep the aspect ratio as requested
              borderRadius: '12px', // Rounded corners
              overflow: 'hidden',
            }}
          >
            <video
              src={hostingURL + welcomeLady}
              poster={hostingURL + welcomeLadyPoster}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              autoPlay
              muted
              loop
              playsInline
            ></video>
            {/* Video icon */}
            <div
              style={{
                position: 'absolute',
                top: '5px',
                right: '10px',
                width: '15px',
                height: '15px',
              }}
            >
              <img src={hostingURL + videoIcon} alt="Video Icon" width="15px" height="15px" />
            </div>
            {/* Bottom text */}
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                textAlign: 'center',
                color: 'white',
                fontWeight: 'regular',
                fontSize: '12px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional dark background for readability
              }}
            >
              Make a call for live product demo
            </div>
          </div>
        </div>
      )}

      <ModalContext.Provider value={{ modalState, setModalState }}>
        <Modal show={showModal} onHide={toggleModal} centered backdrop={modalState}>
          <Modal.Body className="modal2">
            {currentPage === "Body" && <Body onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
            {currentPage === "Main" && <Main onPageChange={handleNavigation} callDuration={callDuration} />}
            {currentPage === "Headers" && <Headers onPageChange={handleNavigation} callDuration={callDuration} setShowModal={setShowModal} />}
            {currentPage === "FirstPage" && <FirstPage onPageChange={handleNavigation} callDuration={callDuration} />}
            {currentPage === "FeedPage" && <FeedPage onPageChange={handleNavigation} callDuration={callDuration} />}
            {currentPage === "Permission" && <Permission onPageChange={handleNavigation} setShowModal={setShowModal} callDuration={callDuration} />}
          </Modal.Body>
        </Modal>
      </ModalContext.Provider>
    </div>
  );
};

export default WebPage;
