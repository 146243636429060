import React from 'react';
import './PermissionPopup.css'; // Make sure this is correctly linked
import permissionImage from '../images/PermissionPopup.png'; // Image for Chrome
import safariImage from '../images/safariPermissionPopup.png'; // Image for Safari
import picon from "../images/PermissionIcon.png"; // Adjust path as necessary

import footerlogo1 from '../images/SHOPANDRETAILlogo.png';

const PermissionPopup = ({ onClose, browserType }) => {
  // Handler to close popup if the overlay (but not the content) is clicked
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  // Define instructions based on browser type
  const getInstructions = () => {
    if (browserType === 'safari') {
      return (
        <ol>
          <li>
            Click the "Safari" menu and open Settings
          </li>
          <li>Give permission of microphone and camera.</li>
        </ol>
      );
    } else if (browserType === 'chrome') {
      return (
        <ol>
          <li>
            Click the browser's info icon <img src={picon} alt="info icon" style={{ width: '14px', height: '14px' }} />
          </li>
          <li> Turn on microphone and camera.</li>
        </ol>
      );
    } else {
      return (
        <ol>
          <li>1. Enable microphone and camera permissions in your browser settings.</li>
        </ol>
      );
    }
  };

  // Conditionally render the image based on browser type
  const getImage = () => {
    return browserType === 'safari' ? safariImage : permissionImage;
  };

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="popup-header">Please give permission for microphone access</div>
        <div className="popup-body">
          <img src={getImage()} alt="Permission Required" className="popup-image" />
          <div className="popup-text">
            <p>We need your permission to use your microphone and camera</p>
            {getInstructions()}
          </div>
        </div>
        <div className="row marginRL">
            <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100px',marginBottom: window.innerWidth > 768 ? '-50px' : '-20px' }}>
              <a
                href="https://shoparonline.com/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',  // Ensures vertical alignment
                  justifyContent: 'center'
                }}
              >
                <span
                  style={{
                    marginRight: '5px',
                    fontSize: '10px',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    lineHeight: '11px' // Matches the line height with the logo height
                  }}
                >
                  Powered by
                </span>
                <img 
        src={footerlogo1} 
          className="footer" 
          id="footer" 
          alt="Saro Logo" 
          style={{
           maxWidth:"100%", // Matches the logo height with the text line height
            height: "auto",
            display: 'block',
            verticalAlign: 'middle' // Aligns the logo vertically in the middle
          }} 
        />
              </a>
            </div>
          </div>
      </div>
    </div>
  );
};

export default PermissionPopup;
