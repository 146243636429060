import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './Speak.css';
import { startCall, endCall, toggleMicrophone, toggleVideo } from '../CallPage/landing';
import footerlogo from '../../images/SHOPARlogo.png';
import footerlogo1 from '../../images/SHOPANDRETAILlogo.png';
import { hostingURL, retailerNAME } from '../constant';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../../hooks/useModalState';

const Headers = ({ onPageChange, setShowModal }) => {
  const [showMicrophone, setShowMicrophone] = useState(true);
  const [showMicrophoneDisable, setShowMicrophoneDisable] = useState(false);
  const [showVideoCall, setShowVideoCall] = useState(true);
  const [showVideoCallDisable, setShowVideoCallDisable] = useState(false);
  const { modalState, setModalState } = useModalContext();

  const navigate = useNavigate();
  const [behaviorRating, setBehaviorRating] = useState(0);
  const [callQualityRating, setCallQualityRating] = useState(0);
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setModalState('static');
  }, []);

  const validate = () => {
    const newErrors = {};

    if (behaviorRating === 0) {
      newErrors.behaviorRating = "Please rate our staff.";
    }

    if (callQualityRating === 0) {
      newErrors.callQualityRating = "Please rate the call quality.";
    }

    if (comment.trim() === '') {
      newErrors.comment = "Please provide a comment.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const RatingAPI = async () => {
    if (!validate()) {
      return;
    }

    const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXRhaWxlcklEIjoiaGFiaXR0IiwiaWF0IjoxNzE4MDUxMTY3fQ.k7NwgAYBHlh5YbVU-KCcMZsHo-eHSD0HZTpsVGIZyao";
    const body = {
      retailerName: retailerNAME,
      clerkID: "khazanay2",
      behaviorRating: behaviorRating,
      callQualityRating: callQualityRating,
      knowledgeRating: 5,
      comment: comment
    };

    try {
      const response = await axios.post('https://us-central1-testimagedownload-c7093.cloudfunctions.net/dev/addClerkRatingsForRetailers', body, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        //console.log('Rating Added successful:', response.data);
        // Redirect or update UI here
      } else {
        console.error('Failed to add rating:', response.data);
      }
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      RatingAPI();
      window.location.reload(); // Reload the page after submitting
    }
  };
  

  const navigateToHome = () => {
    setShowModal(false); // Close the modal
    onPageChange("Body"); // Reset to Body page for the next time the modal opens
    window.location.reload(); // Reload the page after skipping
  };
  

  return (
    <div className="container-fluid text-center justify-content-center d-flex flex-column saro-rating-page">
      <h3 className="headers1" style={{
    marginTop: window.innerWidth >= 768 ? '-90px' : '0px',  // Add margin-top for desktop view
  }}>How was your experience with this service?</h3>
      <div>
        <div className="d-flex justify-content-between ratingStar" style={{
    marginTop: window.innerWidth >= 768 ? '60px' : '0px',  // Add margin-top for desktop view
  }} >
          <div
            className="d-flex flex-column"
            style={{
              alignItems: window.innerWidth >= 1024 ? 'flex-start' : 'center',
            }}
          >
            <p className="Headerpara1" style={{ marginBottom: '-5px' }}> {/* Adjust this value to reduce space */}
              Were our staff well-informed and friendly?
            </p>
            <div className="rate" style={{ padding: window.innerWidth >= 1024 ? '0px' : '0px 10px' }}>
              <input type="radio" id="staff-star5" name="staff-rate" value="5" onChange={() => setBehaviorRating(5)} />
              <label htmlFor="staff-star5" title="text">5 stars</label>
              <input type="radio" id="staff-star4" name="staff-rate" value="4" onChange={() => setBehaviorRating(4)} />
              <label htmlFor="staff-star4" title="text">4 stars</label>
              <input type="radio" id="staff-star3" name="staff-rate" value="3" onChange={() => setBehaviorRating(3)} />
              <label htmlFor="staff-star3" title="text">3 stars</label>
              <input type="radio" id="staff-star2" name="staff-rate" value="2" onChange={() => setBehaviorRating(2)} />
              <label htmlFor="staff-star2" title="text">2 stars</label>
              <input type="radio" id="staff-star1" name="staff-rate" value="1" onChange={() => setBehaviorRating(1)} />
              <label htmlFor="staff-star1" title="text">1 star</label>
            </div>
            {errors.behaviorRating && <p style={{ color: 'red' }}>{errors.behaviorRating}</p>}
          </div>
          <div
            className="d-flex flex-column align-items-center rate-the-call"
          >
            <p className="Headerpara1" style={{ marginBottom: '-5px' }}> {/* Adjust this value to reduce space */}
              Rate the call quality
            </p>
            <div className="rate">
              <input type="radio" id="call-star5" name="call-rate" value="5" onChange={() => setCallQualityRating(5)} />
              <label htmlFor="call-star5" title="text">5 stars</label>
              <input type="radio" id="call-star4" name="call-rate" value="4" onChange={() => setCallQualityRating(4)} />
              <label htmlFor="call-star4" title="text">4 stars</label>
              <input type="radio" id="call-star3" name="call-rate" value="3" onChange={() => setCallQualityRating(3)} />
              <label htmlFor="call-star3" title="text">3 stars</label>
              <input type="radio" id="call-star2" name="call-rate" value="2" onChange={() => setCallQualityRating(2)} />
              <label htmlFor="call-star2" title="text">2 stars</label>
              <input type="radio" id="call-star1" name="call-rate" value="1" onChange={() => setCallQualityRating(1)} />
              <label htmlFor="call-star1" title="text">1 star</label>
            </div>
            {errors.callQualityRating && <p style={{ color: 'red' }}>{errors.callQualityRating}</p>}
          </div>
        </div>

        <div className="container-fluid text-center d-flex flex-column align-items-center textmsg" style={{
    marginTop: window.innerWidth >= 768 ? '0px' : '0px',  // Add margin-top for desktop view
  }}>
          <textarea className="comment-textarea" placeholder="Comment..." onChange={(e) => setComment(e.target.value)}></textarea>
          {errors.comment && <p style={{ color: 'red' }}>{errors.comment}</p>}
        </div>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-row btn-div"
            style={{
              justifyContent: window.innerWidth <= 768 ? 'flex-start' : 'center',
              paddingLeft: window.innerWidth <= 768 ? '20px' : '0px',
              width: '100%',
            }}>
            <div className="d-flex flex-column gradientbtn button_body"
              style={{
                marginRight: window.innerWidth <= 768 ? '10px' : '0',
                
              }}>
              <button
                onClick={handleSubmit}
                className="bb1"
                aria-label="Submit"
                style={{
                  width: '120px',  /* Set the width */
                  height: '25px',  /* Set the height */
                  padding: '10px 20px',  /* Set the padding */
                  fontSize: '16px',  /* Set the font size */
                  borderRadius: '25px'  /* Set the border radius */
                }}
              >
                Submit
              </button>
            </div>
            <div
              className="d-flex flex-column align-items-center justify-content-center align-self-end button_body2"
              style={{
                marginLeft: window.innerWidth <= 768 ? '10px' : '0px',
              }}>
              <button
                onClick={navigateToHome}
                className="bb1Outline"
                aria-label="Skip"
                style={{
                  width: '120px',  /* Set the width */
                  height: '25px',  /* Increase the height to allow for padding */
                  fontSize: '16px',  /* Keep font size the same */
                  borderRadius: '25px',  /* Keep the border radius */
                  lineHeight: '1',  /* Adjust line height to avoid text overflow */
                  overflow: 'hidden',  /* Ensure text doesn't overflow */
                }}
              >
                Skip
              </button>
            </div>

          </div>
        </div>
        <div className="row marginRL">
          <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: '100px', marginBottom : "-100px" }}>
            <a
              href="https://shoparonline.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: 'black',
                display: 'flex',
                alignItems: 'center',  // Ensures vertical alignment
                justifyContent: 'center'
              }}
            >
              <span
                style={{
                  marginRight: '5px',
                  fontSize: '10px',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: '11px' // Matches the line height with the logo height
                }}
              >
                Powered by
              </span>
              <img 
        src={footerlogo1} 
          className="footer" 
          id="footer" 
          alt="Saro Logo" 
          style={{
           maxWidth:"100%", // Matches the logo height with the text line height
            height: "auto",
            display: 'block',
            verticalAlign: 'middle' // Aligns the logo vertically in the middle
          }} 
        />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Headers;
