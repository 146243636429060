import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { useNavigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {saroAPI} from '../src/Components/constant';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('ShopARonline')
);

// export async function fetchCallDetails(customerName, retailerName) {
//   //const navigate = useNavigate(); // Define useNavigate here
//   try {

//     const response = await fetch(`${saroAPI}/startCall`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         "retailerName": retailerName,
//         "userName": customerName
//       }),
//     });
//     if (response.ok) {
//       console.log('API request succeeded hurry');
//       // Parse the response JSON
//       const responseData = await response.json();

//       // Extract session ID, token, and other necessary data from the response
//       const { sessionId, token, callUUID, userName: clerkName } = responseData.data;

//       // Redirect to another page with the extracted data
//       // window.location.href = `/CallPage/Main?`;

//       // When navigating
//       //navigate(`FeedBack/FeedPage?sessionID=${sessionId}&token=${token}`);
//     } else {
//       // Handle other API errors
//       console.error('API Error:', response.statusText);
//       // Show alert for the error message
//       alert('Oh!!! All clerks are busy now.  achaaaaa');
//     }


//   } catch (error) {
//     console.error('API Crash Error:', error.message);
//     alert('Oh!!! All clerks are busy now.');
//   }
// }
