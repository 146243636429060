import React, { useState, useEffect } from 'react';
import './Call.css';
import { startCall, endCall, toggleMicrophone, toggleVideo, setHandleVideoToggleRef } from './landing';
import { useData } from '../../Context.js';
import { useModalContext } from '../../hooks/useModalState';
import { saroAPI, retailerNAME, imageUrl, siteLOGO } from '../constant';
import livestatus from '../../images/live-black.png';
import clerkimg from '../../images/Group54.svg';
import zoom from '../../images/Zoom.svg';
import end from '../../images/End.svg';
import videoicon from '../../images/Video.svg';
import videoDicon from '../../images/VideoD.svg';
import footerlogo from '../../images/SHOPARlogo.png';
import footerlogo1 from '../../images/SHOPANDRETAILlogo.png';
import microDIcon from '../../images/MicroD.svg';
import microIcon from '../../images/Micro.svg';
import endcallicon from '../../images/endCall.png';
import gradientbgbtn from '../../images/Rectangle12.svg';
import outlinebgbtn from '../../images/Rectangle13.svg';

const Auto = ({ onPageChange }) => {
  const [isVideoOn, setIsVideoOn] = useState(
    sessionStorage.getItem('isVideoOn') === 'true'
  );
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(
    sessionStorage.getItem('isMicrophoneOn') === 'true'
  );
  const [isPublisherReady, setIsPublisherReady] = useState(false);
  const { data } = useData();
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('');
  const [callUUID, setCallUUID] = useState('');
  const [clerkName, setClerkName] = useState('');
  const [retailerName, setRetailerName] = useState('');
  const [callStartTime, setCallStartTime] = useState(null);
  const [callDuration, setCallDuration] = useState(0);
  const { modalState, setModalState } = useModalContext();

  const handleVideoToggle = () => {
    setIsVideoOn(prevState => !prevState);
    toggleVideo(isVideoOn);
  };

  const navigateToRatingScreen = () => {
    endCall(); 
    onPageChange("Headers"); 
  }

  const handleMicrophoneToggle = () => {
    setIsMicrophoneOn(prevState => !prevState);
    toggleMicrophone(isMicrophoneOn);
  };

  useEffect(() => {
    if (data && data.sessionID) {
      const sessionId = data.sessionID;
      const token = data.token;
      const callUUID = data.callUUID;
      const clerkName = data.userName;
      const retailerName = data.retailerName;
      const callType = data.callType;

      setSessionId(sessionId || '');
      setToken(token || '');
      setCallUUID(callUUID || '');
      setClerkName(clerkName || '');
      setRetailerName(retailerName || '');

      const sessionInstance = startCall(sessionId, token, callUUID, clerkName, retailerName, callType, onPageChange);

      if (sessionInstance) {
        sessionInstance.on('streamCreated', () => {
          setCallStartTime(new Date());
        });
      }

      return () => {
        endCall();
      };
    }
  }, [data]);

  const handleCallButtonClick = () => {
    if (navigator.onLine) {
      //console.log('Internet is available. Initiating call...');
    } else {
      //console.log('Internet is not available. Cannot initiate call.');
      alert('Internet connection is not available. Please check your network connection.');
    }
  };

  useEffect(() => {
    setModalState('static');
    if (callStartTime) {
      const interval = setInterval(() => {
        const currentTime = new Date();
        const duration = Math.floor((currentTime - callStartTime) / 1000);
        setCallDuration(duration);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [callStartTime]);

  useEffect(() => {
    // Set the handleVideoToggle function reference when the component mounts
    setHandleVideoToggleRef(navigateToRatingScreen);
}, []);

  const formatDuration = () => {
    const hours = Math.floor(callDuration / 3600);
    const minutes = Math.floor((callDuration % 3600) / 60);
    const seconds = callDuration % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const [showCenterDiv, setShowCenterDiv] = useState(false);
  const toggleCenterDiv = () => {
    setShowCenterDiv(!showCenterDiv);
  };

  const cursorStyle = showCenterDiv ? 'default' : 'pointer';

  const handleZoomToggle = () => {
    const elem = document.documentElement;
    const isInFullscreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

    if (!isInFullscreen) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div className="container-fluid text-center CallbodyContainer">
      <div className="row callbodyRow">
      <div className="col-2 fullwidthSm Div-sm-FullScreen upperDiv">
  <div className="d-flex flex-column justify-content-between align-items-center logoDiv">
    {/* <img src={siteLOGO} alt="Logo" className="img-fluid furniture" /> */}

    <a href="https://shoparonline.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'black' }}>
      <div className="footer-container" style={{ position: 'absolute', bottom: '0', left: '0', textAlign: 'left', marginLeft: '55px' }}>
      <img 
        src={footerlogo1} 
          className="footer" 
          id="footer" 
          alt="Saro Logo" 
          style={{
           width:"55px", // Matches the logo height with the text line height
           marginBottom:"20px",
            height: "auto",
            display: 'block',
            verticalAlign: 'middle' // Aligns the logo vertically in the middle
          }} 
        />
      </div>
    </a>
  </div>
</div>

        <div className="col-8 fullwidthSm heightsm middleDiv">
          <div className="overlayy d-flex flex-column justify-content-between">
            <div className="d-flex justify-content-between livebtn z_index">
              <div className='liveLogoDiv'>
              {/* <img src={siteLOGO} alt="Logo" className="liveLogofurniture" /> */}
                <img src={livestatus} className='livestatusimg' width={'50px'} alt="Logo" />
              </div>
              <div className='callDurationDiv'>
                <p>{formatDuration()}</p>
              </div>
           
            </div>
            <div className="ringing-message">
              Ringing<span className="ringing-dots"></span>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center videoNavbar z_index">
              <div className="d-flex justify-content-end callerDiv">
                <div className="caller-info" id="publisher">
                  {showCenterDiv && (
                    <div id="centerDiv" className="centerDiv d-flex flex-column justify-content-evenly">
                      <div className="headicon d-flex justify-content-center">
                        <img src={endcallicon} alt="Logo" className="imgicon5" />
                      </div>
                      <h3 className="end1">End the conversation?</h3>
                      <div className="d-flex flex-column">
  <div className="d-flex flex-column align-items-center justify-content-center button_body">
    <button
      onClick={() => { 
        endCall(); 
        onPageChange("Headers"); 
      }} 
      className="bb1 end-button"  // Applied bb1 class here
      style={{ 
        width: '100px',  // Adjust this value to match the original button size
        height: '25px',  // Adjust this value to match the original button size
        fontSize: '16px',  // Match the font size
        fontWeight: 'bold', // To match the original button font weight
        padding: '0',
      }}
      aria-label="End"
    >
      End
    </button>
  </div>

  <div className="d-flex flex-column align-items-center justify-content-center button_body">
    <button
            onClick={() => { 
              toggleCenterDiv();  // Only close the popup, don't end the call
            }} 
      className="bb1Outline end-button"  // Applied bb1Outline class here
      style={{ 
        marginTop: "2px",
        width: '100px',  // Adjust this value to match the original button size
        height: '25px',  // Adjust this value to match the original button size
        fontSize: '16px',  // Match the font size
        fontWeight: 'bold', // To match the original button font weight
        padding: '0',
      }}
      aria-label="No"
    >
      No
    </button>
  </div>
</div>

                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-evenly V-navbar">
                <img src={`${imageUrl}${isMicrophoneOn ? microIcon : microDIcon}`} alt="Microphone Toggle" style={{ cursor: cursorStyle }} onClick={handleMicrophoneToggle} />
                {data.callType !== 'voice' && data.callType !== 'singleCamera' && (
                  <img src={`${imageUrl}${isVideoOn ? videoicon : videoDicon}`} alt="Video Toggle" style={{ cursor: cursorStyle }} onClick={handleVideoToggle} />
                )}
                <img src={`${imageUrl}${end}`} alt="Logo" onClick={showCenterDiv ? null : toggleCenterDiv} style={{ cursor: cursorStyle }} />
                <img src={`${imageUrl}${zoom}`} alt="Zoom" onClick={handleZoomToggle} style={{ cursor: cursorStyle }} />
              </div>
            </div>
            <div className="video-call" id="subscriber">
              {/* Include OTPublisher component */}
            </div>
          </div>
        </div>
        <div className="col-2 fullwidthSm footerlogoSm lowerDiv">
          <div className=" labelDiv">
           
          </div>
          <img src={footerlogo1} className="img-fluid footerlogo" id="footer" alt="Logo" />
        </div>
      </div>
    </div>
  );
};

export default Auto;
